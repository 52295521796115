import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { useBreakpoint } from "hooks/use-breakpoint"

import PaddedContent from "components/paddedContent"
import styles from "styles/components/articles-grid.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const ArticleItem = ({...item}) => {
  return (
    <div className={styles.article}>
      <div className={styles.imageWrapper}>
        {(item.heroImage?.fluid || item.image?.fluid) && (
          <Img
            fluid={item.heroImage?.fluid || item.image?.fluid}
            style={{ height: "100%" }}
            alt={item.heroImage?.title || item.image?.title}
            imgStyle={{
              transition: "opacity 0.5s ease, transform 0.5s ease",
            }}
          />
        )}
      </div>
      <div className={styles.text}>
        {item.category && (
          <div className={styles.label}>{item.category.text}</div>
        )}
        <div className={styles.title}>{item.title}</div>
        <div className={styles.description}>
          {item.description}
        </div>
      </div>
    </div>
  )
}

const ArticlesGrid = ({ articles: gridItems, team, external }) => {
  const [isTablet] = useBreakpoint(styles.breakpointTwoCols)
  // the following two lines rely on useBreakpoint defaulting to true
  // on load. so if that changes, change 8 to 9
  const [displayNum, setDisplayNum] = useState(team ? gridItems.length : 8)
  const [numToLoad, setNumToLoad] = useState(8)
  const [allLoaded, setAllLoaded] = useState(gridItems.length <= displayNum)

  // handle when we enter/leave tablet mode
  // sometimes we'll have to adjust the number of articles
  // showing to maintain full rows as much as possible
  useEffect(() => {
    if (!allLoaded && isTablet && displayNum % 2 !== 0) {
      // entering tablet mode
      setDisplayNum(displayNum - 1)
      setNumToLoad(8)
    } else if (!allLoaded && !isTablet && displayNum % 3 !== 0) {
      // leaving tablet mode
      let newNum = displayNum + 3 - (displayNum % 3)
      setDisplayNum(newNum)
      // since we're adding articles, check if allLoaded
      setAllLoaded(gridItems.length <= newNum)
      setNumToLoad(9)
    }
  }, [displayNum, isTablet, gridItems.length, allLoaded])

  // then slice according to displayNum
  let filteredItems = gridItems.slice(0, displayNum)

  // if articles changes that means filters changed, so reset grid
  useEffect(() => {
    setDisplayNum(isTablet ? 8 : 9)
    setAllLoaded(gridItems.length <= (isTablet ? 8 : 9))
  }, [gridItems.length, isTablet])

  let threeItems = gridItems.length === 3

  let dummyItems = []
  if (gridItems.length % 2 === 1) {
    dummyItems = [
      <div className={cx("articleWrapper", "dummy")} />,
      <div className={cx("articleWrapper", "dummy")} />,
    ]
  } else if (gridItems.length % 2 === 2) {
    dummyItems = [<div className={cx("articleWrapper", "dummy")} />]
  }

  const noItemText = 'No blog posts found.'

  return (
    <PaddedContent mobilePadding={8}>
      <div className={styles.container}>
        <div
          className={cx("gridContainer", {
            threeItems,
          })}
        >
          {team && (
            filteredItems.length ? filteredItems.map((item, i) => (
              <div
                key={item.slug}
                className={cx("articleWrapper", "team", {
                  threeItems,
                })}
              >
                <div className={styles.innerWrapper}>
                  <div className={styles.teamMember}>
                    <div className={styles.imageWrapper}>
                      {item.icon?.fluid && (
                        <Img fluid={item.icon.fluid} alt={item?.icon?.title}/>
                      )}
                    </div>
                    <div className={styles.text}>
                      <h6>{item.title}</h6>
                      <p>
                        <em>{item.subtitle}</em>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )) : noItemText
          )
          }
          {external && (
            filteredItems.length ? filteredItems.map((item, i) => (
              <div
                key={item.title}
                className={cx("articleWrapper", {
                  threeItems,
                })}
              >
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <div className={styles.article}>
                    <div className={styles.imageWrapper}>
                      {item.image?.fluid &&(
                        <Img
                          fluid={item.image.fluid}
                          style={{ height: "100%" }}
                          imgStyle={{
                            transition: "opacity 0.5s ease, transform 0.5s ease",
                          }}
                          alt={item.image.title}
                        />)
                      }
                    </div>
                    <div className={styles.text}>
                      {item.source && (
                        <div className={styles.label}>{item.source}</div>
                      )}
                      <div className={styles.title}>{item.title}</div>
                      <div className={styles.description}>
                        {item.description}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )) : noItemText
          )}
          {!team && !external && (
            filteredItems.length ? filteredItems.map((item, i) => (
              // <div key={article.slug} className={styles.articleWrapper}>
              <div
                key={item.slug || item.link}
                className={cx("articleWrapper", {
                  threeItems,
                })}
              >
                {item.slug ? (
                  <Link to={`/blog/${item.slug}/`}>
                    <ArticleItem {...item} />
                  </Link>
                ) : (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <ArticleItem {...item} />
                  </a>
                )}
              </div>
            )) : noItemText
          )
          }
          {dummyItems}
        </div>
        {!allLoaded && (
          <div className={styles.buttonWrapper}>
            <button
              onClick={() => {
                let newNum = displayNum + numToLoad
                setAllLoaded(gridItems.length <= newNum)
                setDisplayNum(newNum)
              }}
            >
              View More
            </button>
          </div>
        )}
      </div>
    </PaddedContent>
  )
}

export default ArticlesGrid
